import React, { createContext, useReducer, ReactElement, useEffect, useState, useContext } from 'react';
import CookiePopup from '../components/cookie-popup';
import Cookies from 'js-cookie';

type Action = {
    type: 'acceptAll' | 'declineAll' | 'showPopup';
};
type Dispatch = (action: Action) => void;

const COOKIE_NAME = 'gdpr-google-analytics';
const COOKIE_POPUP = 'cookie-consent';
const CookieConsentStateContext = createContext<boolean | undefined>(undefined);
const CookieConsentDispatchContext = createContext<Dispatch | undefined>(undefined);

// Initial value is cookie value OR preferred value but not yet set

const CookieConsentProvider: React.FC = ({ children }): ReactElement => {
    const initialCookieValue = Cookies.get(COOKIE_NAME) === 'true' || false;
    const initialPopupValue = Cookies.get(COOKIE_POPUP)
        ? JSON.parse(Cookies.get(COOKIE_POPUP) || '')
        : { isVisible: true };

    const [popup, setPopup] = useState({ isVisible: initialPopupValue.isVisible });
    const [state, dispatch] = useReducer((state: boolean, action: Action) => {
        switch (action.type) {
            case 'acceptAll':
                setPopup({ isVisible: false });
                return true;
            case 'declineAll':
                setPopup({ isVisible: false });
                return false;
            case 'showPopup':
                setPopup({ isVisible: true });
                return false;
            default:
                throw new Error();
        }
    }, initialCookieValue);

    useEffect(() => {
        Cookies.set(COOKIE_NAME, JSON.stringify(state));
    }, [state]);

    useEffect(() => {
        Cookies.set(COOKIE_POPUP, JSON.stringify(popup));
    }, [popup]);

    return (
        <CookieConsentStateContext.Provider value={state}>
            <CookieConsentDispatchContext.Provider value={dispatch}>
                {popup.isVisible && <CookiePopup />}
                {children}
            </CookieConsentDispatchContext.Provider>
        </CookieConsentStateContext.Provider>
    );
};

const useCookieConsentState = () => {
    const context = useContext(CookieConsentStateContext);
    if (context === undefined) {
        throw new Error('useCookieConsentState must be used within a CookieConsentStateProvider');
    }
    return context;
};

const useCookieConsentDispatch = () => {
    const context = useContext(CookieConsentDispatchContext);
    if (context === undefined) {
        throw new Error('useCookieConsentDispatch must be used within a CookieConsentDispatchProvider');
    }
    return context;
};

export { CookieConsentProvider, useCookieConsentDispatch, useCookieConsentState };
