import React from 'react';
import styled from '@emotion/styled';
import tw from 'tailwind.macro';
import logoSmall from '../images/logo-small.svg';
import { Link } from 'gatsby';
import { useCookieConsentDispatch } from '../providers/CookieConsent';

const A = styled.a`
    ${tw`border-green-500 border-b-2 hover:text-green-500 hover:border-green-500`}
`;

export default function Footer() {
    const cookieConsentDispatch = useCookieConsentDispatch();

    return (
        <div className="pt-8 pb-4 bg-grey-200 text-cyan-700text-xs font-medium">
            <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center">
                <div className="flex flex-col lg:flex-row items-center">
                    <div className="flex flex-col md:flex-row items-center mb-8 lg:mb-4">
                        <p className="lg:mr-10 mb-2 md:mb-0 ">© Swisspeers AG {new Date().getFullYear()}</p>
                    </div>
                    <div className="flex flex-col md:flex-row items-center mb-8 lg:mb-4">
                        <Link
                            to="/kontakt"
                            className="md:mr-8 px-2 mb-2 md:mb-0 border-b-2 border-transparent hover:border-yellow-400  text-sm uppercase"
                        >
                            Kontakt
                        </Link>
                        <Link
                            to="/datenschutz"
                            className="md:mr-8 px-2 mb-2 md:mb-0 border-b-2 border-transparent hover:border-yellow-400 text-sm uppercase"
                        >
                            Datenschutz
                        </Link>
                        <button
                            className="md:mr-8 px-2 mb-2 md:mb-0 border-b-2 border-transparent hover:border-yellow-400  text-sm uppercase"
                            onClick={() => cookieConsentDispatch({ type: 'showPopup' })}
                        >
                            Cookies
                        </button>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-center mb-8 lg:mb-4">
                    <Link to="/">
                        <img src={logoSmall} alt="Logo" className="object-contain mb-0" />
                    </Link>
                </div>
            </div>
        </div>
    );
}
