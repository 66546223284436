import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import tw from 'tailwind.macro';
import { useCookieConsentDispatch } from '../providers/CookieConsent';
import { Link } from 'gatsby';

type Action = {
    type: 'acceptAll' | 'declineAll' | 'showPopup';
};
type Dispatch = (dispatch: Action) => void;

export default () => {
    const dispatch = useCookieConsentDispatch();

    return (
        <motion.section
            className="fixed bottom-0 bg-gray-200 w-full flex flex-col md:flex-row justify-between items-center py-4 px-4 z-20"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.5 }}
        >
            <p className="text-sm md:text-base leading-relaxed md:mr-8 mb-4 md:mb-0">
                Diese Webseite verwendet Cookies zur Verbesserung der Nutzererfahrung sowie für statistische
                Auswertungen zum Besucherverhalten. Mehr Informationen dazu finden Sie in unserer{' '}
                <Link to="/datenschutz">Datenschutzerklärung</Link>.
            </p>
            <div className="flex-shrink-0">
                {/* <motion.button
        className="md:mr-8 px-2 mb-2 md:mb-0 border-b-2 border-grey-300 hover:border-yellow-400 text-sm"
        onClick={() => dispatch({ type: "declineAll" })}
      >
        Ablehnen
      </motion.button> */}
                <motion.button
                    className="inline-flex bg-yellow-400 text-cyan-700 hover:bg-yellow-500 hover:text-white hover:shadow-xl text-sm font-semibold rounded-full py-3 px-8 focus:outline-none focus:shadow-outline shadow-lg"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => dispatch({ type: 'acceptAll' })}
                >
                    Akzeptieren
                </motion.button>
            </div>
        </motion.section>
    );
};
