import React, { ReactElement } from 'react';
import Footer from './footer';
import { CookieConsentProvider } from '../providers/CookieConsent';

type Props = {
    siteTitle: string;
};
const Layout: React.FC<Props> = ({ children, siteTitle = '' }): ReactElement => {
    return (
        <CookieConsentProvider>
            <div className="flex flex-col min-h-screen text-sm md:text-base overflow-x-hidden">
                <div className="leading-normal tracking-normal text-cyan-700 bg-grey-300 flex-grow">
                    <main>{children}</main>
                </div>
                <Footer siteTitle={siteTitle} />
            </div>
        </CookieConsentProvider>
    );
};

export default Layout;
